var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "all-table" },
    [
      _c(
        "el-table",
        {
          ref: "dragTable",
          staticClass: "table",
          attrs: {
            data: _vm.tableData,
            "row-key": "columnId",
            border: "",
            "max-height": "300",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              width: "80",
              "class-name": "allowDrag",
              align: "center",
            },
          }),
          !_vm.disabled
            ? _c("el-table-column", {
                attrs: { align: "center", label: "操作", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("i", {
                            staticClass: "el-icon-remove operateDel",
                            on: {
                              click: function ($event) {
                                return _vm.tableDelRow(scope.row)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3538170415
                ),
              })
            : _vm._e(),
          _vm._t("tableMiddle"),
          _vm._l(_vm.columnLabel, function (item) {
            return _c("el-table-column", {
              key: item.prop,
              attrs: {
                label: item.label,
                align: item.align || "center",
                prop: item.prop,
                width: item.width,
                minWidth: item.minWidth,
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        item.type == "input"
                          ? _c("el-input", {
                              model: {
                                value: scope.row["" + item.prop],
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "" + item.prop, $$v)
                                },
                                expression: "scope.row[`${item.prop}`]",
                              },
                            })
                          : _c("span", [
                              _vm._v(_vm._s(scope.row["" + item.prop])),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }